import React from 'react';
import ReactMarkdown from 'markdown-to-jsx';
import RichText from '@shipae/components-sandbox/component/rich-text';
import {
  HelpCenterFlowType,
} from 'src/infrastructure/gatsby/types/help-center-flow';
import {
  Title,
  Section,
} from './styles';
import Flow from './flow';
import gloabalReactMdOptions from '../shared/react-makdown-options';

const HelpCenterFlow: React.FC<HelpCenterFlowType> = ({
  id,
  sectionId,
  title,
  content,
}) => (
  <Section
    id={sectionId}
    data-test="section-help-center-flow"
    data-ga={`HelpCenterFlow-${ id }`}
  >
    {title && <Title>{title}</Title> }
    <RichText>
      <ReactMarkdown options={gloabalReactMdOptions}>
        {content || ''}
      </ReactMarkdown>
    </RichText>
    <Flow />
  </Section>
);

export default HelpCenterFlow;
