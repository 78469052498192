/* eslint no-confusing-arrow: 0 */
import styled from 'styled-components';
import {
  white, black, g100, g200, secondaryGreen400,
} from '@shipae/components-sandbox/component/colors';
import CheckMarkCircleIcon
  from '@shipae/components-sandbox/component/icons/check-mark-circle';
import SmartLinkTheme from 'components/smart-link/theme';
import { media, fonts } from 'views/theme';

export const Section = styled.section`
  padding: 9rem calc((100% - var(--container-width)) / 2);
  background-color: ${ g100() };
  text-align: center;
`;
export const Title = styled.h1`
  ${ fonts.displayl200 }
`;

export const Tree = styled.div`
  padding: 3.2rem 0 0 0;
`;
export const TreeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 2.2rem;
  min-height: 20rem;
  justify-content: center;

  & img {
    min-width: 5rem;
    height: 5rem;
  }
`;
export const Leaf = styled.div`
  display: inline-block;
  margin: 1.5rem;
  background-color: ${ white() };
  cursor: pointer;
  border: 2px solid ${ g200() };
  &:hover {
    border-color: ${ black() };
  }
  transition: ease-in .3s border-color;
  border-radius: 8px;
  width: 30rem;
  min-width: 27rem;
  min-height: 20rem;
  ${ fonts.bodys200 }

  ${ media.sm } {
    width: auto;
  }
`;
type BranchProps = {
  selected?: boolean,
};
export const Branch = styled(Leaf)<BranchProps>`
  &:hover {
    border-color: ${ (pr) => pr.selected
    ? secondaryGreen400() : black() };
  }
  border-color: ${ (pr) => pr.selected ? secondaryGreen400() : g200() };
  position: relative;

  &>div.selected-icon {
    display: ${ (pr) => pr.selected ? 'block' : 'none' };
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
  }
`;
export const Step = styled.div`
  margin-bottom: 3.5rem;
`;

export const SmartLinkThemeStep = {
  Main: styled(SmartLinkTheme.Main)`
    color: ${ black() };
    text-decoration: none;
  `,
};
export const LeafTitle = styled.strong`
  ${ fonts.displayxs300 }
  display: block;
  margin: 1rem 0;
`;
export const ChildrenTitle = styled.h2`
  ${ fonts.displays200 }
  margin-bottom: 1.5rem;
`;
export const SelectedIcon = styled(CheckMarkCircleIcon).attrs({
  className: 'selected-icon',
})``;
export const StepChildren = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
