/* eslint max-len: 0 */
import React, { useState, MouseEvent } from 'react';

import { useTranslation } from 'src/views/misc/localization';
import SmartLink from 'components/smart-link';
import TREE, { FlowItemType } from './tree';
import {
  Step,
  Tree,
  Branch,
  Leaf,
  LeafTitle,
  ChildrenTitle,
  TreeItem,
  SmartLinkThemeStep,
  SelectedIcon,
  StepChildren,
} from './styles';
import { findFlowItem, getStepsHistory, isStepSelected } from './service';

const initialStep: FlowItemType = {
  id: '',
  prevStepId: undefined,
  title: '',
  gaText: '',
  description: '',
  value: TREE,
};

type StepState = {
  step: FlowItemType,
};
type StepsState = {
  stepsHistory: FlowItemType[],
};
type FlowProps = {
};
const Flow: React.FC<FlowProps> = () => {
  const { t } = useTranslation();
  const [{ stepsHistory }, setStepsHistory] = useState<StepsState>({
    stepsHistory: [],
  });
  const [{ step }, setStep] = useState<StepState>({ step: initialStep });

  const handleFlowItemClick = (e: MouseEvent<HTMLDivElement>) => {
    const id = e?.currentTarget?.getAttribute('data-id');
    const flowItem = id && findFlowItem(id, TREE);

    // save current step
    if (id && flowItem) {
      // For useAnalyticsListener to capture parent data-ga
      // before unmounting the step
      setTimeout(() => setStep({ step: flowItem }), 50);
    }

    // save steps history
    if (flowItem) {
      setStepsHistory({
        stepsHistory: getStepsHistory(flowItem, TREE),
      });
    }

    // scroll to current step
    setTimeout(() => {
      const currentStep = document.getElementById('help-center-current-step');
      if (currentStep) {
        currentStep.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }, 0);
  };

  const renderStepItem = (stp: FlowItemType) => {
    if (typeof stp.value === 'string') {
      return (
        <Leaf
          data-id={stp.id}
          key={stp.id}
          data-ga-text={stp.gaText}
        >
          <SmartLink
            href={stp.value as string}
            theme={SmartLinkThemeStep}
          >
            <TreeItem>
              {stp.icon && (
                <img
                  loading="lazy"
                  src={stp.icon.url}
                  alt={stp.icon.alt}
                />
              )}
              <LeafTitle>{t(stp.title)}</LeafTitle>
              {stp.description && t(stp.description)}
            </TreeItem>
          </SmartLink>
        </Leaf>
      );
    }

    return (
      <Branch
        data-id={stp.id}
        data-ga-trigger
        data-ga-text={stp.gaText}
        onClick={handleFlowItemClick}
        selected={isStepSelected(stp, [...stepsHistory, step])}
        key={stp.id}
      >
        <SelectedIcon width={2.2} height={2.2} />
        <TreeItem>
          {stp.icon && (
            <img
              loading="lazy"
              src={stp.icon.url}
              alt={stp.icon.alt}
            />
          )}
          <LeafTitle>{t(stp.title)}</LeafTitle>
          {stp.description && t(stp.description)}
        </TreeItem>
      </Branch>
    );
  };

  return (
    <Tree>
      <ChildrenTitle>
        {t('helpCenter.topSubtitle')}
      </ChildrenTitle>
      {stepsHistory.map((historyStep: FlowItemType) => historyStep.value
        && Array.isArray(historyStep.value)
        && (
          <Step key={historyStep.id}>
            {historyStep.childrenTitle && <ChildrenTitle>{t(historyStep.childrenTitle)}</ChildrenTitle>}
            <StepChildren>
              {historyStep.value.map(renderStepItem)}
            </StepChildren>
          </Step>
        ))}
      <Step
        id="help-center-current-step"
      >
        {step.childrenTitle && <ChildrenTitle>{t(step.childrenTitle)}</ChildrenTitle>}
        <StepChildren>
          {
            step.value
            && Array.isArray(step.value)
            && step.value.map(renderStepItem)
          }
        </StepChildren>
      </Step>
    </Tree>
  );
};

export default Flow;
