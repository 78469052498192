import { FlowItemType } from './tree';

export const findFlowItem = (
  stepId: string,
  tree: Array<FlowItemType>,
): FlowItemType | undefined => {
  let res;

  const recursiveFunc = (
    stpId: string,
    tr: Array<FlowItemType>,
  ): FlowItemType | undefined => tr.find(
    (step: FlowItemType): undefined => {
      if (step.id === stpId) {
        res = step;
        return undefined;
      }

      if (step.value && Array.isArray(step.value)) {
        recursiveFunc(stpId, step.value);
      }

      return undefined;
    },
  );

  recursiveFunc(stepId, tree);

  return res;
};

export const getStepsHistory = (
  step: FlowItemType,
  tree: FlowItemType[],
): FlowItemType[] => {
  if (!step || !step.prevStepId) {
    return [];
  }

  const initialStep = {
    id: '',
    prevStepId: undefined,
    title: '',
    gaText: '',
    description: '',
    value: tree,
  };
  let res: FlowItemType[] = [];
  let parentItem: FlowItemType | undefined = findFlowItem(
    step.prevStepId,
    tree,
  );

  while (parentItem) {
    res = [
      parentItem,
      ...res,
    ];
    parentItem = parentItem && parentItem.prevStepId
      ? findFlowItem(parentItem.prevStepId, tree)
      : undefined;
  }

  return [initialStep, ...res];
};

export const isStepSelected = (
  step: FlowItemType,
  stepsHistory: FlowItemType[],
): boolean => !!stepsHistory.find((stp: FlowItemType) => stp.id === step.id);
