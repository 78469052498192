type TranslateFunc = (key: string) => string;
type IconWithAlt = {
  url: string,
  alt: string,
};
export type FlowItemType = {
  id: string,
  prevStepId?: string,
  icon?: IconWithAlt,
  title: string,
  description?: string,
  childrenTitle?: string,
  value: string | Array<FlowItemType>,
  gaText: string,
};

const TREE: Array<FlowItemType> = [
  {
    id: '1',
    prevStepId: '0',
    title: 'helpCenter.business',
    gaText: 'Business',
    childrenTitle: 'helpCenter.subtitleBelowItem1',
    icon: {
      url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/briefcase_354b3fa602.svg', // eslint-disable-line
      alt: 'Business Icon',
    },
    value: [
      {
        id: '11',
        prevStepId: '1',
        title: 'helpCenter.contactSales',
        gaText: 'Contact Sales',
        description: 'helpCenter.contactSalesDescription',
        childrenTitle: 'helpCenter.whatServiceDoYouNeed',
        icon: {
          url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/business_deal_handshake_abaed1aada.svg', // eslint-disable-line
          alt: 'Contact Sales Icon',
        },
        value: [
          {
            id: '111',
            prevStepId: '11',
            title: 'helpCenter.delivery',
            gaText: 'Delivery',
            description: 'helpCenter.deliveryDescription',
            icon: {
              url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/shipment_box_0f4d223232.svg', // eslint-disable-line
              alt: 'Delivery Icon',
            },
            value: '/shipa-delivery-business/work-with-us/',
          },
          {
            id: '112',
            prevStepId: '11',
            title: 'helpCenter.freight',
            gaText: 'Freight',
            description: 'helpCenter.freightDescription',
            icon: {
              url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/shipment_hook_6cd879368b.svg', // eslint-disable-line
              alt: 'Freight Icon',
            },
            value: 'https://www.shipafreight.com/',
          },
          {
            id: '113',
            prevStepId: '11',
            title: 'helpCenter.crossBorderEcommerce',
            gaText: 'Ecommerce Solutions',
            description: 'helpCenter.crossBorderEcommerceDescription',
            childrenTitle: 'helpCenter.crossBorderEcommerceChildrenDescription',
            icon: {
              url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/e_commerce_shopping_bag_1_d2fd5f6abe.svg', // eslint-disable-line
              alt: 'Ecommerce Icon',
            },
            value: [
              {
                id: '1131',
                prevStepId: '113',
                title: 'helpCenter.eFulfillment',
                gaText: 'E-Fulfillment',
                childrenTitle: 'helpCenter.eFulfillmentChildrenDescription',
                icon: {
                  url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/warehouse_storage_9a9792c75c.svg', // eslint-disable-line
                  alt: 'E-Fulfilment Icon',
                },
                value: [
                  {
                    id: '11311',
                    prevStepId: '1131',
                    title: 'helpCenter.kuwaitAndDubai',
                    gaText: 'KuwaitAndDubai',
                    icon: {
                      url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/kuwait_dubai_08ecf73ba3.svg', // eslint-disable-line
                      alt: 'Kuwait and Dubai Icon',
                    },
                    value: '/shipa-delivery-business/work-with-us/',
                  },
                ],
              },
              {
                id: '1132',
                prevStepId: '113',
                title: 'helpCenter.betweenGCCCountries',
                gaText: 'Trade Within The ME',
                icon: {
                  url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/arab_1_1bebc8a07e.svg', // eslint-disable-line
                  alt: 'Between GCC Icon',
                },
                value: '/shipa-delivery-business/work-with-us/',
              },
              {
                id: '1133',
                prevStepId: '113',
                title: 'helpCenter.toFromGCCToRestOfWorld',
                gaText: 'Rest Of The World',
                icon: {
                  url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/ecology_globe_hand_31a644362c.svg', // eslint-disable-line
                  alt: 'Globe Icon',
                },
                value: '/shipa-ecommerce/get-in-touch/',
              },
            ],
          },
        ],
      },
      {
        id: '12',
        prevStepId: '1',
        title: 'helpCenter.customerSupport',
        gaText: 'Customer Support',
        description: 'helpCenter.customerSupportDescription',
        childrenTitle: 'helpCenter.youAreInquiringAs',
        icon: {
          url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/headphones_customer_support_human_9eed7270dc.svg', // eslint-disable-line
          alt: 'Customer Support Icon',
        },
        value: [
          {
            id: '121',
            prevStepId: '12',
            title: 'helpCenter.deliveriesOrEcommerce',
            gaText: 'Deliveries Or Ecommerce',
            childrenTitle: 'Chose one of these:',
            icon: {
              url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/shipment_box_0f4d223232.svg', // eslint-disable-line
              alt: 'Delivery Icon',
            },
            value: '/help-center/call-back-business/',
          },
          {
            id: '122',
            prevStepId: '12',
            title: 'helpCenter.airOceanFreight',
            gaText: 'Air Or Ocean Freight',
            icon: {
              url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/shipment_hook_6cd879368b.svg', // eslint-disable-line
              alt: 'Freight Icon',
            },
            value: 'https://www.shipafreight.com/contact-us/',
          },
        ],
      },
    ],
  },
  {
    id: '2',
    prevStepId: '0',
    title: 'helpCenter.individual',
    gaText: 'Individual',
    childrenTitle: 'helpCenter.subtitleBelowItem2',
    icon: {
      url: 'https://sec-shipa-cms-media.s3.eu-west-1.amazonaws.com/messages_people_person_bubble_square_1_57a53f3f7f.svg', // eslint-disable-line
      alt: 'Individual Icon',
    },
    value: '/help-center/call-back-individual/',
  },
];

export default TREE;
